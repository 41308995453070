 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="115px">
			<el-form-item class="el_form_item" label="用户姓名">
				<el-input class="el_input" v-model="form.name" placeholder="用户姓名" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户手机号">
				<el-input class="el_input" v-model="form.tel" placeholder="用户手机号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="大区搜索">
				<el-select @change="edit_company('area_id')" class="el_inner_width" v-model="form.area_id">
					<el-option label="不限" value="0"></el-option>
					<el-option v-for="item in area_list" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="所属大区" prop="area_name"></el-table-column>
				<el-table-column label="用户姓名/电话">
					<template slot-scope="scope">
						<div>{{scope.row.name}}({{scope.row.tel}})</div>
					</template>
				</el-table-column>
                <el-table-column label="身份类型">
					<template slot-scope="scope">
						<div>{{scope.row.type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注">
					<template slot-scope="scope">
						<div>{{scope.row.mark}}</div>
					</template>
				</el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="140px">
					<template slot-scope="scope">
						<el-button @click="agent_edit(scope.row)" size="mini" type="text">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
        <add :is_show="agent_add.is_show" @refresh='get_page_data'></add>
        <edit :is_show="edit.is_show" :agent_info="edit.data"  @refresh='get_page_data'></edit>
	</div>
</template>

<script>
import Edit from './edit.vue'
    import add from './add.vue'
	export default {
        components:{
			add,
                Edit
		},
		data() {
			return {

				//搜索条件
				form: {
					name:'',// 用户姓名
					tel:'',//手机号
					area_id:'',//大区搜索
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
                agent_add:{
                    is_show:0,
                },
                edit:{
                    is_show:0,
                    data:{},
                },
				area_list:[],
			}
		},
		created() {
			
			//获取页面数据
			this.get_page_data()

			//读取大区列表
			this.area_shipper_set()
		},
		methods: {
            agent_edit(item){
                this.edit.is_show++
                 this.edit.data =item
            },
            open_add_view(){
                this.agent_add.is_show++
            },
			//清空查询条件
			ser_para_clear(){
				this.form={
					name:'',// 用户姓名
					tel:'',//手机号
					area_id:'',//大区搜索
				}
				this.get_page_data()
			},
			//读取数据
			area_shipper_set(){
				this.area_list = []
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'app_user_area_list',
                        name:this.name
                    },callback:(data)=>{
						console.log(data)
                        var data = data.list.list
                        data.forEach(item => {
                            this.area_list.push({
                                name:item.name,
                                id:item.id
                            })
                        });
                    }
                })
			},
			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取后台用户
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_saler_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{
                        if(data.list){
                            //渲染
                            this.list.data=data.list.list
                            for(let item of data.list.list){
                                 switch(item.type){
                                    case '1':item.type_text='销售';break;
                                    case '2':item.type_text='代理';break;
                                }
                            }
                        }else{
                             this.list.data = []
                        }
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max


					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>
<template>
	<el-dialog top="3vh" title="编辑" width="500px" :visible.sync="is_show_in_page" :close="close">
		<el-form status-icon label-position="left" label-width="100px">
            <el-form-item label="用户姓名" >
				<el-input class="el_input" v-model="form.name"></el-input>
			</el-form-item>
             <el-form-item label="用户手机号" >
				<el-input class="el_input" v-model="form.tel"></el-input>
			</el-form-item>
            <el-form-item class="el_form_item" label="身份类别">
				<el-select class="el_input" v-model="form.type" clearable>
					<el-option label="销售" value="1"></el-option>
					<el-option label="代理" value="2"></el-option>
				</el-select>
			</el-form-item>
            <el-form-item label="备注" >
				<el-input class="el_input" v-model="form.mark"></el-input>
			</el-form-item>
            <el-form-item class="el_form_item" label="所属大区">
                <el-autocomplete
                    class="inline-input"
                    v-model="area_nme"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入所属大区"
                    @select="handleSelect"
                ></el-autocomplete>
                <el-button style=" margin-left:10px;" @click="area_nme_add" type="text">添加</el-button>
			</el-form-item>
		</el-form>
		<div style="text-align:center">
			<el-button type="primary" @click="add_view_sub">修改</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
            agent_info:Object
		},
		data() {
			return {
                form:{
                    name:'',
                    tel:'',
                    area_id:''
                },
                area_nme:'',
                user_area_list:[],
				is_show_in_page:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
                    this.area_list()
					this.is_show_in_page=true;
                    this.form ={
                        area_id:this.agent_info.area_id,
                        name:this.agent_info.name,
                        tel:this.agent_info.tel,
                        type:this.agent_info.type,
                        mark:this.agent_info.mark,
                    }
                    this.user_area_list = []
				}
			},
		},
		methods: {
            //大区添加
            area_nme_add(){
               this.$prompt('请输入大区名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    }).then(({ value }) => {
                        this.$my.net.req({
                            data:{
                                mod:'app_user',
                                ctr:'app_user_area_set',
                                name:value,
                            },callback:(data)=>{
                                this.$my.other.msg({
                                    type:'success',
                                    str:'添加成功'
                                });
                                this.user_area_list = []
                                this.area_list()
                            }
                        })
                    }).catch(() => {     
                });
            },
            querySearch(queryString, cb) {
                var restaurants = this.user_area_list;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },

            handleSelect(item) {
                this.form.area_id = item.address
            },
            //读取大区列表
            area_list(){
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'app_user_area_list',
                        name:this.name
                    },callback:(data)=>{
                        var data = data.list.list
                        data.forEach(item => {
                            this.user_area_list.push({
                                value:item.name,
                                address:item.id
                            })
                        });
                        this.area_name = this.agent_info.area_name
                    }
                })
            },
            //弹窗关闭
            close(){
                this.form={
                    name:'',
                    tel:'',
                    area_id:''
                },
                this.area_nme='',
                this.user_area_list=[]
            },
            //提交
            add_view_sub(){
                this.$my.net.req({
                    data:{
                        mod:'app_user',
                        ctr:'app_user_saler_set',
                        ...this.form,
                        id:this.agent_info.id
                    },callback:(data)=>{
                        this.$my.other.msg({
                            type:'success',
                            str:'操作成功'
                        });
                        this.is_show_in_page = false
                        this.close()
                        this.$emit('refresh')
                    }
                })
            }
		}
	}
</script>

<style lang="scss" scoped>

	.el_input{
		width:94%;
       
	}
</style>